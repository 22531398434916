<template>
  <dialog-form-list
    :config="config"
    :title="title"
    :formData="formData"
    @cancel="cancel"
    @confirm="confirm"
  >
    <template v-slot:currentValidYears>
      <div>{{ $util.format(tableRow.secretKeyDistributionList.filter(item => item.type === renewalType)[0].expirationDate) }}</div>
    </template>
  </dialog-form-list>
</template>

<script>

export default {
  props: {
    tableRow: {
      // 当前选中的表格行数据
      type: Object,
      default: () => null,
    },
    renewalType: {
      type: String,
      default: '',
    },
    isOpen: {
      type: Boolean,
      default: false,
    }
  },
  data() {
    const self = this
    return {
      cmd: this.$store.state.cmd,
      formData: {},
      title: this.isOpen ? this.$t('accountPermissionManage.serviceOpen') : this.$t('accountPermissionManage.serviceRenewal'),
      config: [
        // 当前服务截止时间
        {
          tag: 'slot-content',
          label: this.$t('accountPermissionManage.validYears'),
          prop: 'currentValidYears',
          hidden: this.isOpen
        },
        // lang:密钥有效期（月）
        {
          label: this.$t('keyDistribution.validMonths'),
          prop: 'validNum',
          rules: {
            noNull: true,
             type: 'positiveInt',
          },
        },
      ],
    }
  },
  methods: {
    // 取消
    cancel() {
      this.$emit('closePage', 'cancel')
    },
    // 确定
    confirm() {
      const formData = this.$util.copyData(this.formData)
      console.log(this.tableRow)
      formData.type = this.renewalType
      formData.accountUid = this.tableRow.uid
      this.$ajax({
        url: '/' + this.$util.getLocalStorage('apiprefix') + this.$api.accountRenewal,
        data: formData,
        headers: {product_version: 'NORMAL'}
      }).then(() => {
        // lang:新增成功
        this.$element.showMsg(this.$t('base.renewalSuccess'), 'success')
        this.$forceUpdate()
        this.$emit('closePage', 'add')
      })
    },

  },
}
</script>
